<template>
  <div>
    <v-row
      v-if="!veranstaltung.loader && veranstaltung.data"
      justify="center"
      class="pt-5 mt-5"
    >
      <v-col
        cols="12"
        :style="`color: ${template.colors.inline_primary_text};`"
        v-if="!veranstaltung.data.gastgeberverein"
      >
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn icon :to="`/wettkampf/${veranstaltung.id}`" exact>
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="text-center" align-self="center">
            <v-icon x-large>
              mdi-podium
            </v-icon>
          </v-col>
          <v-col>
            <h3 class="white--text font-weight-light">
              {{ veranstaltung.data.art }}
            </h3>
            <h2
              class="font-weight-bold"
              :style="`template: ${template.colors.inline_primary_text};`"
            >
              {{ veranstaltung.data.name }}
            </h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" class="px-2">
        <v-card class="rounded-xl pb-4">
          <v-toolbar
            :elevation="0"
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
          >
            <v-toolbar-title class="font-weight-bold">
              {{ template.var.verband == 'DEU' ? 'Gruppen' : 'Disziplinen' }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              :color="template.colors.inline_primary_text"
              @click="search ? (search = false) : (search = true)"
            >
              <v-icon>
                {{ search ? 'mdi-magnify-close' : 'mdi-magnify' }}
              </v-icon>
            </v-btn>
            <v-btn
              icon
              :color="template.colors.inline_primary_text"
              @click="
                select_sort ? (select_sort = false) : (select_sort = true)
              "
            >
              <v-icon>
                {{ select_sort ? 'mdi-close' : 'mdi-sort' }}
              </v-icon>
            </v-btn>
            <v-menu :color="template.colors.primary" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :dark="!template.light"
                  :light="template.light"
                  icon
                  :color="template.colors.inline_primary_text"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list rounded>
                <v-list-item @click="newdisziplin = true">
                  <v-list-item-avatar>
                    <v-icon>mdi-plus</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>
                    {{ template.var.verband == 'DEU' ? 'Gruppe' : 'Disziplin' }}
                    erstellen
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :to="`/wettkampf/${veranstaltung.id}/disziplinen/csvimport`"
                >
                  <v-list-item-avatar>
                    <v-icon>mdi-import</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>
                    CSV-Import
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-expand-transition>
            <v-row justify="center" v-if="search">
              <v-col cols="11">
                <v-text-field
                  solo
                  light
                  prepend-inner-icon="mdi-magnify"
                  clearable
                  label="Suche"
                  @input="suchen()"
                  v-model="search_text"
                  class="rounded-xl mt-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-expand-transition>
            <v-row justify="center" v-if="select_sort">
              <v-col cols="11">
                <v-select
                  label="Sortierung"
                  filled
                  rounded
                  :items="sort_auswahl"
                  v-model="sortierung"
                  @change="suchen()"
                  class="rounded-xl mt-2"
                ></v-select>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-row
            justify="center"
            class="text-center"
            v-if="disziplinen_gefiltert.length == 0"
          >
            <v-col cols="10">
              <h3 v-if="!search_text">
                Für diesen Wettkampf wurden noch keine
                {{ template.var.verband == 'DEU' ? 'Gruppen' : 'Disziplinen' }}
                angelegt.
              </h3>
              <h3 v-if="search_text" class="mb-5">
                Deine Suche ergab kein Treffer.
              </h3>
            </v-col>
            <v-col cols="10" v-if="!search_text">
              <v-btn
                class="rounded-xl"
                large
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                @click="newdisziplin = true"
              >
                {{
                  template.var.verband == 'DEU' ? 'GRUPPE(N)' : 'DISZIPLIN(EN)'
                }}
                ANLEGEN
              </v-btn>
            </v-col>
          </v-row>
          <v-list two-line rounded v-if="disziplinen_gefiltert.length > 0">
            <v-list-item
              :color="template.colors.primary"
              v-for="(disziplin, i) in disziplinen_gefiltert"
              :key="i"
              :to="`/wettkampf/${veranstaltung.id}/disziplinen/${disziplin.id}`"
            >
              <v-list-item-avatar>
                <v-icon>mdi-medal-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <b>{{ disziplin.name }}</b>
                  {{
                    disziplin.altersklasse
                      ? ' (' + disziplin.altersklasse + ')'
                      : ''
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ parseDate(disziplin.beginn.datum) }} |
                  {{ disziplin.beginn.uhrzeit }} bis
                  {{ disziplin.ende.uhrzeit }} Uhr
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <CreateDisziplin v-if="newdisziplin" @closing="newdisziplin = false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from 'firebase'
import CreateDisziplin from './create_disziplin'

export default {
  name: 'LA_Disziplinen',
  data() {
    return {
      anz_teilnehmer: 0,
      zuschauerinfo: false,
      veranstaltungedit: false,
      editable: true,
      sportstaette: '',
      disziplinen: [],
      disziplinen_gefiltert: [],
      newdisziplin: false,
      csvimport: false,
      search: false,
      search_text: '',
      select_sort: false,
      sort_auswahl: [
        'Beginn (aufsteigend)',
        'Beginn (absteigend)',
        'Name (aufsteigend)',
        'Name (absteigend)',
      ],
    }
  },
  components: {
    CreateDisziplin,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    if (this.$route.params.veranstaltung) {
      if (this.veranstaltung.id != this.$route.params.veranstaltung) {
        store.dispatch('setVeranstaltung', this.$route.params.veranstaltung)
      }
      if (this.veranstaltung.data.art != 'Wettkampf') {
        router.push('/start')
      }
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .collection('Disziplin')
        .orderBy('beginn.datum', 'asc')
        .orderBy('beginn.uhrzeit', 'asc')
        .onSnapshot((snap) => {
          this.disziplinen = []
          var i = 0
          snap.forEach((doc) => {
            this.disziplinen.push(doc.data())
            this.disziplinen[i].id = doc.id
            i++
          })
          this.suchen()
        })
    } else {
      store.dispatch('closeVeranstaltung')
    }
  },
  methods: {
    sortieren() {
      function compareBeginnASC(a, b) {
        if (a.beginn.datum > b.beginn.datum) {
          return 1
        } else if (a.beginn.datum == b.beginn.datum) {
          if (a.beginn.uhrzeit > b.beginn.uhrzeit) {
            return 1
          } else {
            return -1
          }
        } else {
          return -1
        }
      }
      function compareBeginnDESC(a, b) {
        if (a.beginn.datum > b.beginn.datum) {
          return -1
        } else if (a.beginn.datum == b.beginn.datum) {
          if (a.beginn.uhrzeit > b.beginn.uhrzeit) {
            return -1
          } else {
            return 1
          }
        } else {
          return 1
        }
      }
      function compareNameASC(a, b) {
        if (a.name > b.name) {
          return 1
        } else {
          return -1
        }
      }
      function compareNameDESC(a, b) {
        if (a.name > b.name) {
          return -1
        } else {
          return 1
        }
      }

      if (this.sortierung == 'Beginn (aufsteigend)') {
        this.disziplinen.sort(compareBeginnASC)
      }
      if (this.sortierung == 'Beginn (absteigend)') {
        this.disziplinen.sort(compareBeginnDESC)
      }
      if (this.sortierung == 'Name (aufsteigend)') {
        this.disziplinen.sort(compareNameASC)
      }
      if (this.sortierung == 'Name (absteigend)') {
        this.disziplinen.sort(compareNameDESC)
      }
      this.select_sort = false
    },
    suchen() {
      this.sortieren()
      this.disziplinen_gefiltert = []
      if (this.search_text) {
        var suche = this.search_text.split(',')
        for (let s = 0; s < suche.length; s++) {
          for (let i = 0; i < this.disziplinen.length; i++) {
            var add = false
            if (this.disziplinen[i].name.startsWith(suche[s])) {
              add = true
            } else if (this.disziplinen[i].altersklasse.startsWith(suche[s])) {
              add = true
            } else if (
              this.parseDate(this.disziplinen[i].beginn.datum).startsWith(
                suche[s],
              )
            ) {
              add = true
            } else if (
              this.disziplinen[i].beginn.uhrzeit.startsWith(suche[s])
            ) {
              add = true
            } else if (this.disziplinen[i].ende.uhrzeit.startsWith(suche[s])) {
              add = true
            }

            if (add) {
              if (!this.disziplinen_gefiltert.includes(this.disziplinen[i])) {
                this.disziplinen_gefiltert.push(this.disziplinen[i])
              }
            }
          }
        }
      } else {
        this.disziplinen_gefiltert = this.disziplinen
      }
    },
    parseDate(datum) {
      datum = datum.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
  },
}
</script>
