var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticStyle:{"padding-top":"env(safe-area-inset-top)"},attrs:{"value":"true","fullscreen":_vm.$vuetify.breakpoint.mdAndDown,"persistent":_vm.$vuetify.breakpoint.lgAndUp,"transition":"dialog-bottom-transition","max-width":_vm.$vuetify.breakpoint.lgAndUp ? '800' : undefined,"dark":"","content-class":"rounded-xl"}},[_c('v-card',{staticStyle:{"width":"100vw","overflow-x":"hidden"}},[_c('v-toolbar',{style:(("\n        padding-top: env(safe-area-inset-top);\n        height: 80px + env(safe-area-inset-top);\n        color: " + (_vm.template.colors.inline_primary_text) + ";\n      ")),attrs:{"fixed":"","color":_vm.template.colors.primary}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.template.var.verband == 'DEU' ? 'Gruppe' : 'Disziplin')+" erstellen ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":_vm.template.colors.inline_primary_text},on:{"click":function($event){return _vm.closing()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"10","lg":"8"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"filled":"","rounded":"","label":_vm.template.var.verband == 'DEU'
                ? 'Name der Gruppe'
                : 'Name der Disziplin'},model:{value:(_vm.v.name),callback:function ($$v) {_vm.$set(_vm.v, "name", $$v)},expression:"v.name"}}),(_vm.user.data.sportstaetten)?_c('v-select',{attrs:{"rules":[_vm.rules.required],"items":_vm.user.data.sportstaetten,"item-text":"name","item-value":"name","filled":"","rounded":"","label":"Sportstätte"},model:{value:(_vm.v.sportstaette),callback:function ($$v) {_vm.$set(_vm.v, "sportstaette", $$v)},expression:"v.sportstaette"}}):_vm._e(),_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.importname],"filled":"","rounded":"","label":"Importname","hint":"Dieser Name muss in CSV-Importen verwendet werden"},model:{value:(_vm.v.importname),callback:function ($$v) {_vm.$set(_vm.v, "importname", $$v)},expression:"v.importname"}}),_c('v-dialog',{ref:"beginndatum",attrs:{"return-value":_vm.v.beginn.datum,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.v.beginn, "datum", $event)},"update:return-value":function($event){return _vm.$set(_vm.v.beginn, "datum", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.parse_datum(_vm.v.beginn.datum),"label":"Datum","rules":[_vm.rules.required],"filled":"","rounded":"","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.beginndatum),callback:function ($$v) {_vm.beginndatum=$$v},expression:"beginndatum"}},[_c('v-date-picker',{attrs:{"locale":"de","first-day-of-week":"1","min":_vm.veranstaltung.data.beginn.datum,"max":_vm.veranstaltung.data.ende.datum,"scrollable":""},model:{value:(_vm.v.beginn.datum),callback:function ($$v) {_vm.$set(_vm.v.beginn, "datum", $$v)},expression:"v.beginn.datum"}},[_c('v-spacer'),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","rounded":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.beginndatum = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","rounded":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.$refs.beginndatum.save(_vm.v.beginn.datum)
                  _vm.v.ende.datum = _vm.v.beginn.datum}}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{ref:"beginnuhrzeit",staticClass:"mb-4",attrs:{"return-value":_vm.v.beginn.uhrzeit,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.v.beginn, "uhrzeit", $event)},"update:return-value":function($event){return _vm.$set(_vm.v.beginn, "uhrzeit", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.rules.required],"label":"Beginn","filled":"","rounded":"","prepend-icon":"mdi-clock","readonly":""},model:{value:(_vm.v.beginn.uhrzeit),callback:function ($$v) {_vm.$set(_vm.v.beginn, "uhrzeit", $$v)},expression:"v.beginn.uhrzeit"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.beginnuhrzeit),callback:function ($$v) {_vm.beginnuhrzeit=$$v},expression:"beginnuhrzeit"}},[_c('v-time-picker',{attrs:{"format":"24hr","min":_vm.v.beginn.datum == _vm.veranstaltung.data.beginn.datum
                  ? _vm.veranstaltung.data.beginn.uhrzeit
                  : undefined,"max":_vm.v.ende.datum == _vm.veranstaltung.data.ende.datum
                  ? _vm.veranstaltung.data.ende.uhrzeit
                  : undefined,"scrollable":""},model:{value:(_vm.v.beginn.uhrzeit),callback:function ($$v) {_vm.$set(_vm.v.beginn, "uhrzeit", $$v)},expression:"v.beginn.uhrzeit"}},[_c('v-spacer'),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","rounded":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.beginnuhrzeit = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","rounded":"","color":_vm.template.colors.primary},on:{"click":function($event){return _vm.$refs.beginnuhrzeit.save(_vm.v.beginn.uhrzeit)}}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{ref:"endeuhrzeit",staticClass:"mb-4",attrs:{"return-value":_vm.v.ende.uhrzeit,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.v.ende, "uhrzeit", $event)},"update:return-value":function($event){return _vm.$set(_vm.v.ende, "uhrzeit", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Ende","rules":[_vm.rules.required],"filled":"","rounded":"","prepend-icon":"mdi-clock","readonly":""},model:{value:(_vm.v.ende.uhrzeit),callback:function ($$v) {_vm.$set(_vm.v.ende, "uhrzeit", $$v)},expression:"v.ende.uhrzeit"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endeuhrzeit),callback:function ($$v) {_vm.endeuhrzeit=$$v},expression:"endeuhrzeit"}},[_c('v-time-picker',{attrs:{"format":"24hr","scrollable":"","min":_vm.v.beginn.uhrzeit
                  ? _vm.v.beginn.uhrzeit
                  : _vm.v.beginn.datum == _vm.veranstaltung.data.beginn.datum
                  ? _vm.veranstaltung.data.beginn.uhrzeit
                  : undefined,"max":_vm.v.ende.datum == _vm.veranstaltung.data.ende.datum
                  ? _vm.veranstaltung.data.ende.uhrzeit
                  : undefined},model:{value:(_vm.v.ende.uhrzeit),callback:function ($$v) {_vm.$set(_vm.v.ende, "uhrzeit", $$v)},expression:"v.ende.uhrzeit"}},[_c('v-spacer'),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","rounded":"","color":_vm.template.colors.primary},on:{"click":function($event){_vm.endeuhrzeit = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"text":"","rounded":"","color":_vm.template.colors.primary},on:{"click":function($event){return _vm.$refs.endeuhrzeit.save(_vm.v.ende.uhrzeit)}}},[_vm._v(" OK ")])],1)],1),_c('v-divider'),_c('v-checkbox',{attrs:{"label":("Linkregistrierung " + (_vm.v.public ? 'aktiviert' : 'deaktiviert'))},model:{value:(_vm.v.public),callback:function ($$v) {_vm.$set(_vm.v, "public", $$v)},expression:"v.public"}}),_c('v-divider'),_c('v-btn',{staticClass:"my-4",style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"block":"","rounded":"","large":"","color":_vm.template.colors.primary,"disabled":!_vm.check_valid(),"loading":_vm.creating},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Hinzufügen ")],1),_c('v-btn',{staticClass:"my-4",style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"block":"","large":"","outlined":"","rounded":"","color":_vm.template.colors.primary},on:{"click":function($event){return _vm.closing()}}},[_vm._v(" Abbrechen ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }