<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
    content-class="rounded-xl"
  >
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
          color: ${template.colors.inline_primary_text};
        `"
      >
        <v-toolbar-title>
          {{ template.var.verband == 'DEU' ? 'Gruppe' : 'Disziplin' }} erstellen
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          :color="template.colors.inline_primary_text"
          @click="closing()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center" class="mt-4">
          <v-col cols="10" lg="8" class="px-2">
            <v-text-field
              v-model="v.name"
              :rules="[rules.required]"
              filled
              rounded
              :label="
                template.var.verband == 'DEU'
                  ? 'Name der Gruppe'
                  : 'Name der Disziplin'
              "
            ></v-text-field>
            <v-select
              v-model="v.sportstaette"
              v-if="user.data.sportstaetten"
              :rules="[rules.required]"
              :items="user.data.sportstaetten"
              item-text="name"
              item-value="name"
              filled
              rounded
              label="Sportstätte"
            ></v-select>
            <v-text-field
              v-model="v.importname"
              :rules="[rules.required, rules.importname]"
              filled
              rounded
              label="Importname"
              hint="Dieser Name muss in CSV-Importen verwendet werden"
            ></v-text-field>
            <v-dialog
              ref="beginndatum"
              v-model="beginndatum"
              :return-value.sync="v.beginn.datum"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="parse_datum(v.beginn.datum)"
                  label="Datum"
                  :rules="[rules.required]"
                  filled
                  rounded
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="de"
                first-day-of-week="1"
                v-model="v.beginn.datum"
                :min="veranstaltung.data.beginn.datum"
                :max="veranstaltung.data.ende.datum"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="beginndatum = false"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="
                    $refs.beginndatum.save(v.beginn.datum)
                    v.ende.datum = v.beginn.datum
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              class="mb-4"
              ref="beginnuhrzeit"
              v-model="beginnuhrzeit"
              :return-value.sync="v.beginn.uhrzeit"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="v.beginn.uhrzeit"
                  :rules="[rules.required]"
                  label="Beginn"
                  filled
                  rounded
                  prepend-icon="mdi-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                format="24hr"
                v-model="v.beginn.uhrzeit"
                :min="
                  v.beginn.datum == veranstaltung.data.beginn.datum
                    ? veranstaltung.data.beginn.uhrzeit
                    : undefined
                "
                :max="
                  v.ende.datum == veranstaltung.data.ende.datum
                    ? veranstaltung.data.ende.uhrzeit
                    : undefined
                "
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="beginnuhrzeit = false"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="$refs.beginnuhrzeit.save(v.beginn.uhrzeit)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
            <v-dialog
              class="mb-4"
              ref="endeuhrzeit"
              v-model="endeuhrzeit"
              :return-value.sync="v.ende.uhrzeit"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="v.ende.uhrzeit"
                  label="Ende"
                  :rules="[rules.required]"
                  filled
                  rounded
                  prepend-icon="mdi-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                format="24hr"
                v-model="v.ende.uhrzeit"
                scrollable
                :min="
                  v.beginn.uhrzeit
                    ? v.beginn.uhrzeit
                    : v.beginn.datum == veranstaltung.data.beginn.datum
                    ? veranstaltung.data.beginn.uhrzeit
                    : undefined
                "
                :max="
                  v.ende.datum == veranstaltung.data.ende.datum
                    ? veranstaltung.data.ende.uhrzeit
                    : undefined
                "
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="endeuhrzeit = false"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                  text
                  rounded
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  @click="$refs.endeuhrzeit.save(v.ende.uhrzeit)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
            <v-divider></v-divider>
            <v-checkbox
              v-model="v.public"
              :label="`Linkregistrierung ${
                v.public ? 'aktiviert' : 'deaktiviert'
              }`"
            ></v-checkbox>
            <v-divider></v-divider>
            <v-btn
              class="my-4"
              block
              rounded
              large
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              :disabled="!check_valid()"
              @click="create()"
              :loading="creating"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Hinzufügen
            </v-btn>
            <v-btn
              class="my-4"
              block
              large
              outlined
              rounded
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              @click="closing()"
            >
              Abbrechen
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from 'firebase'
import { mapGetters } from 'vuex'

export default {
  name: 'Gruppe-erstellen',
  data() {
    return {
      v: {
        name: '',
        sportstaette: '',
        public: true,
        importname: '',
        beginn: {
          datum: '',
          uhrzeit: '',
        },
        ende: {
          datum: '',
          uhrzeit: '',
        },
      },
      beginndatum: false,
      beginnuhrzeit: false,
      endedatum: false,
      creating: false,
      bisdatum: false,
      valid: false,
      vondatum: false,
      endeuhrzeit: false,
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        importname: (v) =>
          v.match(/(@+)|(,+)|(:+)|\s/)
            ? 'Der Importname enthält unzulässige Zeichen'
            : true,
      },
      counter: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    parse_datum(datum) {
      if (datum) {
        var d = datum.split('-')
        return d[2] + '.' + d[1] + '.' + d[0]
      } else {
        return datum
      }
    },
    addNull(val) {
      if (val >= 10) {
        return val
      } else {
        return '0' + val
      }
    },
    check_valid() {
      if (
        this.v.name &&
        this.v.beginn.datum &&
        this.v.beginn.uhrzeit &&
        this.v.ende.uhrzeit
      ) {
        return true
      } else {
        return false
      }
    },
    async create() {
      if (this.check_valid()) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Veranstaltung')
          .doc(this.veranstaltung.id)
          .collection('Disziplin')
          .add({
            name: this.v.name,
            sportstaette: this.v.sportstaette,
            importname: this.v.importname,
            public: this.v.public,
            beginn: this.v.beginn,
            ende: this.v.ende,
          })
          .then((docRef) => {
            this.closing()
          })
      }
    },
  },
}
</script>
